export const localStorageMixin = {
  methods: {
    saveToLocalStorage(key, value) {
      localStorage.setItem(key, JSON.stringify(value));
    },
    getFromLocalStorage(key, defaultValue = null) {
      const storedValue = localStorage.getItem(key);
      return storedValue ? JSON.parse(storedValue) : defaultValue;
    },
    clearLocalStorage(key) {
      localStorage.removeItem(key);
    },
  },
};