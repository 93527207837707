<template>
  <div class="app-ranking-page">
    <div v-if="dataLoaded"
         class="app-ranking-page-content">

      <div class="new-container">

        <div class="titles-block-wrap">
          <div class="titles-block">
            <h1 class="page-title">
              {{ computedTitle }}
            </h1>

            <h2 class="subtitle">
              {{ computedSubtitle }}
            </h2>
          </div>

          <order-radaso-button :pre-selected="['full-aso-support']" />
        </div>

        <div class="options-and-filter-block">
          <div class="filters-block">

            <div class="filter-item-wrap"
                 v-if="false">
              <custom-dropdown :min-width="'174px'"
                               :dropdown-in-global-block="true"
                               :data-updated="currentStore">
                <template v-slot:header>
                  <span class="capitalized">{{ currentStore }}</span>
                </template>
                <template v-slot:content>
                  <div class="select-options">
                    <div class="option"
                         v-for="item in filtersData.stores"
                         :class="{active: item.title === currentStore}"
                         @click="filterClickHandler('store_key',item.key, item.title, currentStore)">{{ item.title }}
                    </div>
                  </div>
                </template>
              </custom-dropdown>
            </div>

            <div class="filter-item-wrap">
              <custom-dropdown :min-width="'174px'"
                               :dropdown-in-global-block="true"
                               :data-updated="currentCluster">
                <template v-slot:header>
                  <span class="capitalized">{{ currentCluster }}</span>
                </template>
                <template v-slot:content>
                  <div class="select-options">
                    <div class="option"
                         v-for="item in filteredCompData.clusters"
                         :class="{active: activeCluster(item.title)}"
                         @click="filterClickHandler('cluster_key',item.key, item.title, 'currentCluster')">{{
                        item.title
                      }}
                    </div>
                  </div>
                </template>
              </custom-dropdown>
            </div>

            <div class="filter-item-wrap">
              <custom-dropdown :min-width="'192px'"
                               :dropdown-in-global-block="true"
                               @dropdown-closed="dropdownClosed">
                <template v-slot:header>
                  <span class="capitalized">{{ currentCategory }}</span>
                </template>
                <template v-slot:content>
                  <div class="select-options"
                       id="categories-select"
                       ref="options-wrap">
                    <div class="option"
                         v-for="item in filteredCompData.categories"
                         :class="{active: item?.active, 'active-css-class': item.title === currentCategory || activeSubItem(item.title)}">
                      <template v-if="item?.subMenu?.length > 0">
                        <div class="content-item sub-item"
                             :class="{active: item.isActive}"
                             :id="item.id"
                             @click.stop="dropdownItemClickHandler(item.id, $event)">
                          <div class="sub-header value">
                            {{ item.title }}
                            <svg-icon icon="angle-down-solid"/>
                          </div>
                          <div class="sub-content-wrap"
                               :class="sidebarIsCollapsed ? 'left' : 'right'">
                            <div class="sub-content-scroll-area">
                              <div class="sub-content">
                                <div class="sub-menu-item"
                                     v-for="subMenuItem in item.subMenu"
                                     :class="{'active-css-class': subMenuItem.title === currentCategory}"
                                     @click="filterClickHandler('category_slug', subMenuItem.slug, subMenuItem.title, 'currentCategory')">
                                  {{ subMenuItem.title }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <div class="option-wrap"
                             @click="filterClickHandler('category_slug',item.slug, item.title, 'currentCategory')">
                          {{ item.title }}
                        </div>
                      </template>
                    </div>
                  </div>
                </template>
              </custom-dropdown>
            </div>

            <div class="filter-item-wrap">
              <custom-dropdown :min-width="'174px'"
                               :dropdown-in-global-block="true">
                <template v-slot:header>
                  <span class="capitalized">{{ currentIap }}</span>
                </template>
                <template v-slot:content>
                  <div class="select-options">
                    <div class="option"
                         :class="{active: currentIap === 'All'}"
                         @click="filterClickHandler('iap', 'all', 'All', 'currentIap')">All
                    </div>
                    <div class="option"
                         :class="{active: currentIap === 'IAP'}"
                         @click="filterClickHandler('iap', 'iap', 'IAP', 'currentIap')">IAP
                    </div>
                    <div class="option"
                         :class="{active: currentIap === 'No IAP'}"
                         @click="filterClickHandler('iap', 'no-iap', 'No IAP', 'currentIap')">No IAP
                    </div>
                  </div>
                </template>
              </custom-dropdown>
            </div>

            <div v-if="currentStore === 'App Store'"
                 class="filter-item-wrap">
              <custom-dropdown :min-width="'174px'"
                               :dropdown-in-global-block="true">
                <template v-slot:header>
                  <span>{{ currentDevice === 'ipad' ? 'iPad' : 'iPhone' }}</span>
                </template>
                <template v-slot:content>
                  <div class="select-options">
                    <div class="option"
                         :class="{active: currentDevice === 'iPhone'}"
                         @click="filterClickHandler('device', 'iphone', 'iPhone', 'currentDevice')">iPhone
                    </div>
                    <div class="option"
                         :class="{active: currentDevice === 'iPad'}"
                         @click="filterClickHandler('device', 'ipad', 'iPad', 'currentDevice')">iPad
                    </div>
                  </div>
                </template>
              </custom-dropdown>
            </div>

          </div>

          <div class="options-block">
            <custom-dropdown :min-width="'36px'"
                             :with-icon="false"
                             :dropdown-align="'right'"
                             :no-border="true"
                             :with-shadow="true"
                             :dropdown-width="'280px'"
                             :classes="'options-btn'">
              <template v-slot:header>
              <span class="icon">
                <svg-icon icon="table-settings"/>
              </span>
              </template>
              <template v-slot:content>
                <div class="select-options">
                  <div class="option"
                       v-for="(item, idx) in tableColumns"
                       :key="idx">
                    <data-table-checkbox v-if="!item.isFrozen"
                                         :checked="item.isActive"
                                         @input-checked="tableColumnChecked(item)">
                      {{ item.text }}
                    </data-table-checkbox>
                  </div>
                </div>
              </template>
            </custom-dropdown>

            <custom-dropdown v-if="false"
                             :min-width="'36px'"
                             :dropdown-width="'295px'"
                             :with-icon="false"
                             :with-shadow="true"
                             :dropdown-align="'right'"
                             :no-border="true"
                             :classes="'options-btn'">
              <template v-slot:header>
              <span class="icon">
               <svg-icon icon="table-filter"/>
              </span>
              </template>
              <template v-slot:content>
                <div class="select-options">
                  <div class="option active">App Store</div>
                  <div class="option">Google Play</div>
                </div>
              </template>
            </custom-dropdown>
          </div>
        </div>

        <div class="filters-mobile-wrap">
          <filters-dropdown>
            <template v-slot:accordion-body>
              <div class="filters-block-mobile">
                <div class="filter-item-wrap"
                     v-if="false">
                  <custom-dropdown :min-width="'174px'"
                                   :dropdown-in-global-block="true"
                                   :data-updated="currentStore">
                    <template v-slot:header>
                      <span class="capitalized">{{ currentStore }}</span>
                    </template>
                    <template v-slot:content>
                      <div class="select-options">
                        <div class="option"
                             v-for="item in filtersData.stores"
                             :class="{active: item.title === currentStore}"
                             @click="filterClickHandler('store_key',item.key, item.title, currentStore)">{{
                            item.title
                          }}
                        </div>
                      </div>
                    </template>
                  </custom-dropdown>
                </div>

                <div class="filter-item-wrap">
                  <custom-dropdown :min-width="'174px'"
                                   :dropdown-in-global-block="true"
                                   :data-updated="currentCluster">
                    <template v-slot:header>
                      <span class="capitalized">{{ currentCluster }}</span>
                    </template>
                    <template v-slot:content>
                      <div class="select-options">
                        <div class="option"
                             v-for="item in filteredCompData.clusters"
                             :class="{active: activeCluster(item.title)}"
                             @click="filterClickHandler('cluster_key',item.key, item.title, 'currentCluster')">
                          {{ item.title }}
                        </div>
                      </div>
                    </template>
                  </custom-dropdown>
                </div>

                <div class="filter-item-wrap">

                  <custom-dropdown :min-width="'192px'"
                                   :dropdown-in-global-block="true">
                    <template v-slot:header>
                      <span class="capitalized">{{ currentCategory }}</span>
                    </template>
                    <template v-slot:content>
                      <div class="select-options"
                           id="categories-select"
                           ref="options-wrap">
                        <div class="option"
                             v-for="item in filteredCompData.categories"
                             :class="{active: item?.active, 'active-css-class': item.title === currentCategory || activeSubItem(item.title)}">
                          <template v-if="item?.subMenu?.length > 0">
                            <div class="content-item sub-item"
                                 :class="{active: item.isActive}"
                                 @click.stop="dropdownItemClickHandler(item.id, $event)">
                              <div class="sub-header value">
                                {{ item.title }}
                                <svg-icon icon="angle-down-solid"/>
                              </div>
                              <div class="sub-content-wrap">
                                <div class="sub-content-scroll-area">
                                  <div class="sub-content">
                                    <div class="sub-menu-item"
                                         v-for="subMenuItem in item.subMenu"
                                         :class="{'active-css-class': subMenuItem.title === currentCategory}"
                                         @click="filterClickHandler('category_slug', subMenuItem.slug, subMenuItem.title, 'currentCategory')">
                                      {{ subMenuItem.title }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </template>
                          <template v-else>
                            <div class="option-wrap"
                                 @click="filterClickHandler('category_slug',item.slug, item.title, 'currentCategory')">
                              {{ item.title }}
                            </div>
                          </template>
                        </div>
                      </div>
                    </template>
                  </custom-dropdown>
                </div>

                <div class="filter-item-wrap">
                  <custom-dropdown :min-width="'174px'"
                                   :dropdown-in-global-block="true">
                    <template v-slot:header>
                      <span class="capitalized">{{ currentIap }}</span>
                    </template>
                    <template v-slot:content>
                      <div class="select-options">
                        <div class="option"
                             :class="{active: currentIap === 'All'}"
                             @click="filterClickHandler('iap', 'all', 'All', 'currentIap')">All
                        </div>
                        <div class="option"
                             :class="{active: currentIap === 'IAP'}"
                             @click="filterClickHandler('iap', 'iap', 'IAP', 'currentIap')">IAP
                        </div>
                        <div class="option"
                             :class="{active: currentIap === 'No IAP'}"
                             @click="filterClickHandler('iap', 'no-iap', 'No IAP', 'currentIap')">No IAP
                        </div>
                      </div>
                    </template>
                  </custom-dropdown>
                </div>

                <div v-if="currentStore === 'APP_STORE'"
                     class="filter-item-wrap">
                  <custom-dropdown :min-width="'174px'"
                                   :dropdown-in-global-block="true">
                    <template v-slot:header>
                      <span class="capitalized">{{ currentDevice }}</span>
                    </template>
                    <template v-slot:content>
                      <div class="select-options">
                        <div class="option"
                             :class="{active: currentDevice === 'iPhone'}"
                             @click="filterClickHandler('device', 'iphone', 'iPhone', 'currentDevice')">iPhone
                        </div>
                        <div class="option"
                             :class="{active: currentDevice === 'iPad'}"
                             @click="filterClickHandler('device', 'ipad', 'iPad', 'currentDevice')">iPad
                        </div>
                      </div>
                    </template>
                  </custom-dropdown>
                </div>
              </div>
            </template>
          </filters-dropdown>

          <custom-dropdown :min-width="'36px'"
                           :with-icon="false"
                           :dropdown-align="'right'"
                           :no-border="true"
                           :with-shadow="true"
                           :dropdown-width="'280px'"
                           :classes="'options-btn mobile-options-btn'">
            <template v-slot:header>
              <span class="icon">
                <svg-icon icon="table-settings"/>
              </span>
            </template>
            <template v-slot:content>
              <div class="select-options">
                <div class="option"
                     :class="{inactive: item.isFrozen}"
                     v-for="(item, idx) in tableColumns"
                     :key="idx">
                  <data-table-checkbox v-if="!item.isFrozen"
                                       :checked="item.isActive"
                                       @input-checked="tableColumnChecked(item)">
                    {{ item.text }}
                  </data-table-checkbox>
                </div>
              </div>
            </template>
          </custom-dropdown>
        </div>

      </div>

      <div class="app-rank-table" v-if="topRankings?.length > 0">
        <data-table :headers="tableColumns"
                    wrapper-id="app-ranking-table"
                    :items="topRankings"
                    :pagination="pageMeta"
                    :sorting-meta="sortingMeta"
                    :items-per-page="+pageMeta.per_page"
                    :rows-per-page-items="[50, 100, 250, 500, 1000]"
                    :fixed-width="true"
                    :show-actions="false"
                    :sorting-keys="{direction: 'order_direction', sorting: 'order_by', paginate: 'per_page'}"
                    @update-sort="updateSort">

          <template v-slot:averages>
            <td class="averages"
                :style="tableStyleCombined(tableHeaderItem('position'))"
                :hidden="!tableHeaderItem('position')?.isActive"
                :aria-hidden="!tableHeaderItem('position')?.isActive"
                :class="tableClassCombined(tableHeaderItem('position'))">
              <div class="inner-content"></div>
            </td>

            <td class="averages"
                :style="tableStyleCombined(tableHeaderItem('title'))"
                :hidden="!tableHeaderItem('title')?.isActive"
                :aria-hidden="!tableHeaderItem('title')?.isActive"
                :class="tableClassCombined(tableHeaderItem('title'))">
              <div class="inner-content">
                <div class="averages-title">Averages</div>
              </div>
            </td>

            <td class="averages"
                :style="tableStyleCombined(tableHeaderItem('developer'))"
                :hidden="!tableHeaderItem('developer')?.isActive"
                :aria-hidden="!tableHeaderItem('developer')?.isActive"
                :class="tableClassCombined(tableHeaderItem('developer'))">
              <div class="inner-content"></div>
            </td>

            <td class="averages"
                :style="tableStyleCombined(tableHeaderItem('rating'))"
                :hidden="!tableHeaderItem('rating')?.isActive"
                :aria-hidden="!tableHeaderItem('rating')?.isActive"
                :class="tableClassCombined(tableHeaderItem('rating'))">
              <div class="inner-content">
                <div v-if="avgRankings.rating"
                     class="rating-wrap">
                  <div class="rating-icon"></div>
                  {{ avgRankings?.rating ?? '' }}
                </div>
                <template v-else>-</template>
              </div>
            </td>

            <td class="averages"
                :style="tableStyleCombined(tableHeaderItem('score'))"
                :hidden="!tableHeaderItem('score')?.isActive"
                :aria-hidden="!tableHeaderItem('score')?.isActive"
                :class="tableClassCombined(tableHeaderItem('score'))">
              <div class="inner-content">
                <div class="css-icons aso-icon"
                     v-if="avgRankings.aso_score && avgRankings.aso_score !== ''"></div>
                <div class="rating-wrap">
                  {{ avgRankings?.aso_score ?? '' }}
                </div>
              </div>
            </td>

            <td class="averages"
                :style="tableStyleCombined(tableHeaderItem('ratings_amount'))"
                :hidden="!tableHeaderItem('ratings_amount')?.isActive"
                :aria-hidden="!tableHeaderItem('ratings_amount')?.isActive"
                :class="tableClassCombined(tableHeaderItem('ratings_amount'))">
              <div class="inner-content">{{ avgRankings?.voted ?? '' }}</div>
            </td>

            <td class="averages"
                :style="tableStyleCombined(tableHeaderItem('update_frequency_days'))"
                :hidden="!tableHeaderItem('update_frequency_days')?.isActive"
                :aria-hidden="!tableHeaderItem('update_frequency_days')?.isActive"
                :class="tableClassCombined(tableHeaderItem('update_frequency_days'))">
              <div class="inner-content">{{ avgRankings?.update_frequency_days ?? '' }}</div>
            </td>

            <td class="averages"
                :style="tableStyleCombined(tableHeaderItem('age_days'))"
                :hidden="!tableHeaderItem('age_days')?.isActive"
                :aria-hidden="!tableHeaderItem('age_days')?.isActive"
                :class="tableClassCombined(tableHeaderItem('age_days'))">
              <div class="inner-content">{{ avgRankings?.age_days ?? '' }}</div>
            </td>

            <td class="averages"
                :style="tableStyleCombined(tableHeaderItem('screenshots_amount'))"
                :hidden="!tableHeaderItem('screenshots_amount')?.isActive"
                :aria-hidden="!tableHeaderItem('screenshots_amount')?.isActive"
                :class="tableClassCombined(tableHeaderItem('screenshots_amount'))">
              <div class="inner-content">{{ avgRankings?.screenshots ?? '' }}</div>
            </td>

            <td class="averages"
                :style="tableStyleCombined(tableHeaderItem('unique_screenshots_amount'))"
                :hidden="!tableHeaderItem('unique_screenshots_amount')?.isActive"
                :aria-hidden="!tableHeaderItem('unique_screenshots_amount')?.isActive"
                :class="tableClassCombined(tableHeaderItem('unique_screenshots_amount'))">
              <div class="inner-content">{{ avgRankings?.unique_screenshots_amount ?? '' }}</div>
            </td>

            <td class="averages"
                :style="tableStyleCombined(tableHeaderItem('has_video_flag'))"
                :hidden="!tableHeaderItem('has_video_flag')?.isActive"
                :aria-hidden="!tableHeaderItem('has_video_flag')?.isActive"
                :class="tableClassCombined(tableHeaderItem('has_video_flag'))">
              <div class="inner-content"></div>
            </td>

            <td class="averages"
                :style="tableStyleCombined(tableHeaderItem('unique_locales_amount'))"
                :hidden="!tableHeaderItem('unique_locales_amount')?.isActive"
                :aria-hidden="!tableHeaderItem('unique_locales_amount')?.isActive"
                :class="tableClassCombined(tableHeaderItem('unique_locales_amount'))">
              <div class="inner-content">{{ avgRankings?.locales_amount ?? '' }}</div>
            </td>

            <td class="averages"
                :style="tableStyleCombined(tableHeaderItem('updated_at'))"
                :hidden="!tableHeaderItem('updated_at')?.isActive"
                :aria-hidden="!tableHeaderItem('updated_at')?.isActive"
                :class="tableClassCombined(tableHeaderItem('updated_at'))">
              <div class="inner-content"></div>
            </td>

            <td class="averages"
                :style="tableStyleCombined(tableHeaderItem('in_app_purchase_from'))"
                :hidden="!tableHeaderItem('in_app_purchase_from')?.isActive"
                :aria-hidden="!tableHeaderItem('in_app_purchase_from')?.isActive"
                :class="tableClassCombined(tableHeaderItem('in_app_purchase_from'))">
              <div class="inner-content">${{ avgRankings?.in_app_purchase_from ?? '' }}</div>
            </td>

            <td class="averages"
                :style="tableStyleCombined(tableHeaderItem('in_app_purchase_to'))"
                :hidden="!tableHeaderItem('in_app_purchase_to')?.isActive"
                :aria-hidden="!tableHeaderItem('in_app_purchase_to')?.isActive"
                :class="tableClassCombined(tableHeaderItem('in_app_purchase_to'))">
              <div class="inner-content">${{ avgRankings?.in_app_purchase_to ?? '' }}</div>
            </td>

            <td class="averages"
                :style="tableStyleCombined(tableHeaderItem('size_mb'))"
                :hidden="!tableHeaderItem('size_mb')?.isActive"
                :aria-hidden="!tableHeaderItem('size_mb')?.isActive"
                :class="tableClassCombined(tableHeaderItem('size_mb'))">
              <div class="inner-content">
                {{ avgRankings?.size_mb ?? '' }}MB
              </div>
            </td>
          </template>

          <template v-slot:items="{item}">
            <td :style="tableStyleCombined(tableHeaderItem('position'))"
                :hidden="!tableHeaderItem('position')?.isActive"
                :aria-hidden="!tableHeaderItem('position')?.isActive"
                :class="tableClassCombined(tableHeaderItem('position'))">
              <div class="inner-content">
                <div class="difference"
                     v-if="item.difference !== '0'"
                     :class="differenceClass(item.difference)">
                  <template v-if="differenceClass(item.difference) === 'green'">
                    <template v-if="item.difference !== 'in'">+</template>
                  </template>
                  <span v-if="item.difference !== 0">{{ item.difference }}</span>
                </div>
                <div class="position">
                  {{ item.position }}
                </div>
              </div>
            </td>

            <td :style="tableStyleCombined(tableHeaderItem('title'))"
                :hidden="!tableHeaderItem('title')?.isActive"
                :aria-hidden="!tableHeaderItem('title')?.isActive"
                :class="tableClassCombined(tableHeaderItem('title'))">
              <div class="inner-content cursor-pointer"
                   @click="appClickHandler(item)">
                <div class="app-info">

                  <app-image
                      :width="32"
                      :height="32"
                      :lazy-src="item.logo"
                      class="app-logo"
                  />
                  <span class="app-title"
                        :title="item.title">
                           {{ item.title }}
                         </span>
                </div>
              </div>
            </td>

            <td :style="tableStyleCombined(tableHeaderItem('developer'))"
                :hidden="!tableHeaderItem('developer')?.isActive"
                :aria-hidden="!tableHeaderItem('developer')?.isActive"
                :class="tableClassCombined(tableHeaderItem('developer'))">
              <div class="inner-content">
                <div class="text-overflow w130mobile"
                     :style="tableStyleCombined(tableHeaderItem('developer'))"
                     :title="item.developer">
                  {{ item.developer }}
                </div>
              </div>
            </td>

            <td :style="tableStyleCombined(tableHeaderItem('rating'))"
                :hidden="!tableHeaderItem('rating')?.isActive"
                :aria-hidden="!tableHeaderItem('rating')?.isActive"
                :class="tableClassCombined(tableHeaderItem('rating'))">
              <div class="inner-content">
                <div v-if="item.formattedRating"
                     class="rating-wrap">
                  <div class="rating-icon"></div>

                  {{ item.formattedRating }}
                </div>
                <template v-else>-</template>
              </div>
            </td>

            <td :style="tableStyleCombined(tableHeaderItem('score'))"
                :hidden="!tableHeaderItem('score')?.isActive"
                :aria-hidden="!tableHeaderItem('score')?.isActive"
                :class="tableClassCombined(tableHeaderItem('score'))">
              <div class="inner-content">
                <div class="css-icons aso-icon"
                     v-if="item.score && item.score !== ''"></div>
                <div class="rating-wrap">
                  {{ item.score }}
                </div>
              </div>
            </td>

            <td :style="tableStyleCombined(tableHeaderItem('ratings_amount'))"
                :hidden="!tableHeaderItem('ratings_amount')?.isActive"
                :aria-hidden="!tableHeaderItem('ratings_amount')?.isActive"
                :class="tableClassCombined(tableHeaderItem('ratings_amount'))">
              <div class="inner-content">
                {{ item.ratings_amount }}
              </div>
            </td>

            <td :style="tableStyleCombined(tableHeaderItem('update_frequency_days'))"
                :hidden="!tableHeaderItem('update_frequency_days')?.isActive"
                :aria-hidden="!tableHeaderItem('update_frequency_days')?.isActive"
                :class="tableClassCombined(tableHeaderItem('update_frequency_days'))">
              <div class="inner-content">
                {{ item.update_frequency_days }}
              </div>
            </td>

            <td v-if="false" :style="tableStyleCombined(tableHeaderItem('formatted_downloads_amount'))"
                :hidden="!tableHeaderItem('formatted_downloads_amount')?.isActive"
                :aria-hidden="!tableHeaderItem('formatted_downloads_amount')?.isActive"
                :class="tableClassCombined(tableHeaderItem('formatted_downloads_amount'))">
              <div class="inner-content">
                {{ item.formatted_downloads_amount }}
              </div>
            </td>

            <td :style="tableStyleCombined(tableHeaderItem('age_days'))"
                :hidden="!tableHeaderItem('age_days')?.isActive"
                :aria-hidden="!tableHeaderItem('age_days')?.isActive"
                :class="tableClassCombined(tableHeaderItem('age_days'))">
              <div class="inner-content">
                {{ item.age_days }}
              </div>
            </td>

            <td :style="tableStyleCombined(tableHeaderItem('screenshots_amount'))"
                :hidden="!tableHeaderItem('screenshots_amount')?.isActive"
                :aria-hidden="!tableHeaderItem('screenshots_amount')?.isActive"
                :class="tableClassCombined(tableHeaderItem('screenshots_amount'))">
              <div class="inner-content">
                {{ item.screenshots_amount }}
              </div>
            </td>

            <td :style="tableStyleCombined(tableHeaderItem('unique_screenshots_amount'))"
                :hidden="!tableHeaderItem('unique_screenshots_amount')?.isActive"
                :aria-hidden="!tableHeaderItem('unique_screenshots_amount')?.isActive"
                :class="tableClassCombined(tableHeaderItem('unique_screenshots_amount'))">
              <div class="inner-content">
                {{ item.unique_screenshots_amount }}
              </div>
            </td>

            <td v-if="false" :style="tableStyleCombined(tableHeaderItem('has_video_flag'))"
                :hidden="!tableHeaderItem('has_video_flag')?.isActive"
                :aria-hidden="!tableHeaderItem('has_video_flag')?.isActive"
                :class="tableClassCombined(tableHeaderItem('has_video_flag'))">
              <div class="inner-content">
                <div class="icon"
                     :class="[{positive: item.has_video_flag, negative: !item.has_video_flag}]">
                  <svg-icon v-if="item.has_video_flag"
                            icon="check-solid"
                            class="video-icon green"/>

                  <svg-icon v-else
                            icon="close"
                            class="video-icon red"/>
                </div>
              </div>
            </td>

            <td :style="tableStyleCombined(tableHeaderItem('unique_locales_amount'))"
                :hidden="!tableHeaderItem('unique_locales_amount')?.isActive"
                :aria-hidden="!tableHeaderItem('unique_locales_amount')?.isActive"
                :class="tableClassCombined(tableHeaderItem('unique_locales_amount'))">
              <div class="inner-content">
                {{ item.unique_locales_amount }}
              </div>
            </td>

            <td :style="tableStyleCombined(tableHeaderItem('updated_at'))"
                :hidden="!tableHeaderItem('updated_at')?.isActive"
                :aria-hidden="!tableHeaderItem('updated_at')?.isActive"
                :class="tableClassCombined(tableHeaderItem('updated_at'))">
              <div class="inner-content">
                {{ item.updated_at }}
              </div>
            </td>

            <td :style="tableStyleCombined(tableHeaderItem('in_app_purchase_from'))"
                :hidden="!tableHeaderItem('in_app_purchase_from')?.isActive"
                :aria-hidden="!tableHeaderItem('in_app_purchase_from')?.isActive"
                :class="tableClassCombined(tableHeaderItem('in_app_purchase_from'))">
              <div class="inner-content">
                <template v-if="item.in_app_purchase_from">${{ item.in_app_purchase_from }}</template>
              </div>
            </td>

            <td :style="tableStyleCombined(tableHeaderItem('in_app_purchase_to'))"
                :hidden="!tableHeaderItem('in_app_purchase_to')?.isActive"
                :aria-hidden="!tableHeaderItem('in_app_purchase_to')?.isActive"
                :class="tableClassCombined(tableHeaderItem('in_app_purchase_to'))">
              <div class="inner-content">
                <template v-if="item.in_app_purchase_to">${{ item.in_app_purchase_to }}</template>
              </div>
            </td>

            <td :style="tableStyleCombined(tableHeaderItem('size_mb'))"
                :hidden="!tableHeaderItem('size_mb')?.isActive"
                :aria-hidden="!tableHeaderItem('size_mb')?.isActive"
                :class="tableClassCombined(tableHeaderItem('size_mb'))">
              <div class="inner-content">
                <template v-if="item.size_mb">{{ item.size_mb }}MB</template>
              </div>
            </td>
          </template>
        </data-table>
      </div>
      <template v-else>
        <div class="new-container">
          <div class="no-data-block">
            <div class="content">
              <span class="emoji">😢</span>
              <span class="text">No data found on your criteria.</span>
            </div>
          </div>
        </div>
      </template>
    </div>

    <template v-else>
      <progress-indicator :loaded="dataLoaded">{{ loaderText }}</progress-indicator>
    </template>

    <details-modal v-if="showAppDetailsModal"
                   :opened="showAppDetailsModal"
                   :app-data="clickedItem"
                   @competitor-changed="modalCompetitorChanged"
                   @modal-closed="showAppDetailsModal = !showAppDetailsModal"/>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import TheHeader from '@/components/TheHeader/index';
import AdvancedDataTable from "@/components/DataTables/AdvancedDataTable";
import CustomRangeInput from "@/components/Forms/CustomRangeInput/index";
import CustomDropdown from "@/components/UI/CustomDropdown/index";
import {httpRequest} from "@/api";
import {appRankingTableConfig} from "@/configs/page-tables-configs/app-ranking";
import {convertDays, formatDate, cropImage, getCookie} from "@/utils/utils";
import SvgIcon from "@/components/UI/SvgIcon";
import TableCheckbox from "@/components/UI/TableCheckbox";
import FiltersDropdown from "@/components/Dropdowns/FiltersDropdown/index";
import DetailsModal from "@/components/AppDetailsComponent/DetailsModal/index.vue";
import { parseQueryStringToObject } from "@/utils/utils"
import { localStorageMixin } from "@/utils/mixins"
import OrderRadasoButton from "@/components/OrderRadaso/Button/index.vue";

export default {
  name: 'RankingKeywords',
  mixins: [localStorageMixin],
  components: {
    OrderRadasoButton,
    SvgIcon,
    'the-header': TheHeader,
    'data-table': AdvancedDataTable,
    'custom-dropdown': CustomDropdown,
    'filters-dropdown': FiltersDropdown,
    'range-input': CustomRangeInput,
    'data-table-checkbox': TableCheckbox,
    DetailsModal,
  },
  data() {
    return {
      title: '',
      loaderText: '',
      currentKeywordCountryName: 'United States',
      currentKeywordCountryCode: 'US',
      currentKeywordCountryId: null,
      dataLoaded: false,
      tableColumns: [],
      topRankings: [],
      avgRankings: {},
      filtersData: {
        stores: [],
        categories: [],
        clusters: [
          {
            key: "top-free",
            id: 7,
            store_id: 1,
            title: "Free",
          },
          {
            key: "top-paid",
            id: 8,
            store_id: 1,
            title: "Paid",
          },
          {
            key: "top-grossing",
            id: 9,
            store_id: 1,
            title: "Grossing",
          },
          {
            key: "top-free",
            id: 10,
            store_id: 2,
            title: "Free",
          },
          {
            key: "top-paid",
            id: 12,
            store_id: 2,
            title: "Paid",
          },
          {
            key: "top-grossing",
            id: 14,
            store_id: 2,
            title: "Grossing",
          },
        ],
      },
      currentStore: 'APP_STORE',
      currentStoreId: 2,
      currentCluster: 'Grossing',
      currentClusterKey: 'top-grossing',
      currentCategory: 'Overall',
      currentCategorySlug: '',
      currentDevice: 'iPhone',
      currentIap: 'All',
      categoryScrolled: 0,
      rankingTableScrolled: 0,
      initialUrlQueryParams: '',
      showAppDetailsModal: false,
      clickedItem: {},
      isSetupFilterFromUrl: false
    }
  }, 
  methods: {
    async filterClickHandler(key, keyValue, value, dataVar) {
      this[dataVar] = value;

      if (key === 'store_key') {
        if (value === 'GOOGLE_PLAY') {
          this.currentStoreId = 1;
        } else {
          this.currentStoreId = 2;
        }
      }

      if (key === 'category_slug') {
        this.currentCategorySlug = keyValue;
      }

      if (key === 'cluster_key') {
        this.currentClusterKey = keyValue;

        if (this.currentDevice === 'ipad') {
          await this.updateSort({
            'cluster_key': this.currentClusterKey + '-ipad'
          });
          return;
        }
      }

      if (key === 'device') {
        this.currentDevice = keyValue;

        if (keyValue === 'ipad') {
          await this.updateSort({
            // 'device': 'ipad',
            'cluster_key': this.currentClusterKey + '-ipad'
          });

        } else {
          await this.updateSort({
            // 'device': 'iphone',
            'cluster_key': this.currentClusterKey
          });
        }
      } else {
        await this.updateSort({
          [key]: keyValue
        });
      }
    },
    tableColumnChecked(item) {
      const colIndex = this.tableColumns.findIndex(colItem => colItem.text === item.text);
      const tableCol = this.tableColumns.map(item => item);
      if (colIndex >= 0 && !item.isFrozen) {
        tableCol[colIndex].isActive = !tableCol[colIndex].isActive;
      }

      this.tableColumns = tableCol.map(item => item);
    },
    setDefaultFilter() {
      const defaultValues = {
          order_by: 'rating',
          order_direction: 'desc',
          store_key: this.currentApp?.store?.key,
          cluster_key: 'top-grossing',
          category_slug: 'overall',
          iap: 'all',
          device: this.currentApp?.store?.key === 'APP_STORE' ? 'iphone' : 'mobile',
        }

      this.updateSort(defaultValues)
    },
    async setTableData() {
      if (JSON.stringify(this.currentApp) !== '{}') {
        const parsedQueryParams = parseQueryStringToObject(this.urlQueryString)
        const orderByFromQueryParams = parsedQueryParams.order_by
        const storeKeyFromQUeryParams = parsedQueryParams.store_key
        const sortDirectionFromQueryParams = parsedQueryParams.order_direction
        const clusterKeyFromQueryParams = parsedQueryParams.cluster_key
        const categoryFromQueryParams = parsedQueryParams.category_slug
        const iapFromQueryParams = parsedQueryParams.iap

        if (this.urlQueryString) {
          this.$store.dispatch('appRanking/SET_SORTING_META', {
          order_by: orderByFromQueryParams,
          order_direction: sortDirectionFromQueryParams,
          store_key: storeKeyFromQUeryParams,
          cluster_key: clusterKeyFromQueryParams,
          category_slug: categoryFromQueryParams,
          iap: iapFromQueryParams,
          device: storeKeyFromQUeryParams === 'APP_STORE' ? 'iphone' : 'mobile',
        });
        } else {
          this.$store.dispatch('appRanking/SET_SORTING_META', {
            order_by: 'rating',
            order_direction: 'desc',
            store_key: this.currentApp?.store?.key,
            cluster_key: 'top-grossing',
            category_slug: 'overall',
            iap: 'all',
            device: this.currentApp?.store?.key === 'APP_STORE' ? 'iphone' : 'mobile',
        });
        }
      
        if (this.initialUrlQueryParams === '') {
          this.$store.dispatch('appRanking/CONSTRUCT_URL_QUERY_STRING', null);
        }
        await this.fetchTableData();
      }
    },
    async fetchTableData() {
      const currentAppId = getCookie('currentAppId');

      if (currentAppId) {
        const queryString = this.urlQueryString ?? '';
        let urlQuery = '?country_code=' + this.currentKeywordCountryCode + '&amount=500' + queryString;

        this.replaceUrl();

        const result = await httpRequest('GET', process.env.VUE_APP_API_URL + this.$ApiUrls.appRanking.TOP_RANKING + urlQuery);
        const list = result?.list;
        const avg = result?.avg;

        const voted = avg?.voted?.toLocaleString('ru-RU', {maximumFractionDigits: 0});
        const size_mb = avg?.size_mb?.toLocaleString('ru-RU', {maximumFractionDigits: 0});
        const age_days = convertDays(avg?.age_days);
        this.avgRankings = {
          ...avg,
          age_days,
          voted,
        };

        if (result?.errors) {
          console.log('error');
        }

        this.topRankings = [];
        for (const key in list) {
          const email = list[key]?.developer?.email ?? '';
          const developer = list[key]?.developer?.title ?? '';
          const formattedRating = list[key]?.rating ? parseFloat(list[key]?.rating).toFixed(1) : '';
          const score = list[key]?.score ? parseInt(list[key]?.score) : ''; 
          const ratings_amount = list[key]?.ratings_amount?.toLocaleString('ru-RU', {maximumFractionDigits: 0});
          const size_mb = list[key]?.size_mb?.toLocaleString('ru-RU', {maximumFractionDigits: 0});
          const age_days = convertDays(list[key]?.age_days);
          let updated_at;
          if (list[key]?.updated_at) {
            updated_at = formatDate(new Date(list[key]?.updated_at), 'month-day-year');
          }

          this.topRankings.push({
            ...list[key],
            ratings_amount,
            age_days,
            updated_at,
            logo: list[key]?.logo,
            email,
            developer,
            formattedRating,
            score,
            size_mb,
          });
        }

        this.dataLoaded = true;
      }

      this.topRankings?.sort((a, b) => (a?.position > b?.position) ? 1 : -1);

      if (this.pageFirstLoad) {
        this.showInitialModal();
        this.pageFirstLoad = false;
      }
    },
    replaceUrl() {
      if (window.history.replaceState) {
        let queryString = this.urlQueryString;
        queryString = queryString.charAt(0).replace('&', '?') + queryString.slice(1);
        window.history.replaceState({}, null, queryString);
      }
    },
    async sendPromisesBundle() {
      const promiseUrls = [
        {
          url: process.env.VUE_APP_API_URL + this.$ApiUrls.user.FILTER_STORES,
          method: 'GET',
        },
        {
          url: process.env.VUE_APP_API_URL + this.$ApiUrls.user.FILTER_CATEGORIES,
          method: 'GET',
        },
        // {
        //   url: process.env.VUE_APP_API_URL + this.$ApiUrls.user.FILTER_CLUSTERS,
        //   method: 'GET',
        // },
      ];
      await this.$store.dispatch('FETCH_PROMISE_ALL_SETTLED', promiseUrls);
      const filterStores = this.promiseSettledData?.data[0]?.value;
      const filterCategories = this.promiseSettledData?.data[1]?.value;
      // const filterClusters = this.promiseSettledData?.data[2]?.value;

      if (this.promiseSettledData.success) {
        if (filterStores) {
          this.filtersData.stores = filterStores;
        }

        if (filterCategories) {
          this.filtersData.categories = this.setCategories(filterCategories);
        }
      }
    },
    setCategories(filterClusters) {
      let categoryArray = [];
      for (const key in filterClusters) {
        if (filterClusters[key].category_id === 0) {
          categoryArray.push(filterClusters[key]);
        }
      }

      for (const key in categoryArray) {
        let item = {...categoryArray[key]};

        item.subMenu = filterClusters.filter(subItem => subItem.category_id === item.id);
        if (item.subMenu?.length > 0) {
          let unshiftedItem = {};

          if (item.title === 'Games') {
            unshiftedItem.title = 'All Games';
          } else {
            unshiftedItem.title = 'All ' + item?.title + ' Apps';
          }

          unshiftedItem.slug = item.slug;
          item.subMenu.sort((a, b) => (a.title > b.title) ? 1 : -1);
          item.subMenu.unshift(unshiftedItem);
        }

        categoryArray[key] = item;
      }

      const sortedArray = categoryArray.sort((a, b) => (a.title > b.title) ? 1 : -1);
      const overall = sortedArray.find(item => item.slug === "overall");
      const tempArray = sortedArray.filter(item => item.slug !== "overall");
      tempArray.unshift(overall);
      return tempArray;
    },
    async updateSort(data) {
      this.dataLoaded = false;
      this.$store.dispatch('appRanking/SET_SORTING_META', data);
      this.$store.dispatch('appRanking/CONSTRUCT_URL_QUERY_STRING', data);
      await this.fetchTableData();
      // this.dataLoading = false;
      this.categorySelectScroll();
      this.initialUrlQueryParams = '';
    },
      setTableColumnsData() {
      this.tableColumns = appRankingTableConfig.headers.map(item => {
        return {
          ...item,
          isActive: true,
        }
      });

      this.categorySelectScroll();
    },
    categorySelectScroll() {
      const optionsWrap = document.getElementById('categories-select');

      if (optionsWrap && window.innerWidth > 767) {
        optionsWrap.addEventListener('scroll', (e) => {
          this.categoryScrolled = optionsWrap.scrollTop;
          this.filtersData.categories.forEach(item => {
            item.isActive = false;
          });
        });
      }
    },
    rankingTableScroll() {
      const rankingTable = document.getElementById('app-ranking-table');
      if (!rankingTable) {
        return;
      }
      // const defaultMaxHeight = 105;
      const defaultMaxHeight = 125;
      let maxHeightCalc = 0;
      const firstDataRow = rankingTable.querySelector('.averages');

      rankingTable.addEventListener('scroll', (e) => {
        this.rankingTableScrolled = rankingTable.scrollTop;
        window.scrollBy(0, rankingTable.scrollTop);
        if (rankingTable.scrollTop === 0) {
          // maxHeightCalc = 200;
          maxHeightCalc = 235;
          firstDataRow.style.height = `54px`;
        } else if (rankingTable.scrollTop <= defaultMaxHeight) {
          maxHeightCalc = defaultMaxHeight - rankingTable.scrollTop;
          firstDataRow.style.height = `calc(54px + ${rankingTable.scrollTop}px)`;
          firstDataRow.style.verticalAlign = 'bottom';
        } else {
          // maxHeightCalc = 30;
          maxHeightCalc = 15;
        }

        rankingTable.style.maxHeight = `calc(100vh - ${maxHeightCalc}px)`;
      });
    },
    differenceClass(str) {
      const value = parseInt(str);
      if (str === 'in') {
        return 'green';
      } else if (str === 'out') {
        return 'red';
      } else if (value < 0) {
        return 'red';
      } else if (value > 0) {
        return 'green';
      }
    },
    dropdownClosed() {
      const optionsWrap = document.getElementById('categories-select');

      if (optionsWrap && window.innerWidth < 992) {
        this.categoryScrolled = 0;
      }

      this.filtersData.categories.forEach(item => {
        item.isActive = false;
      });
    },
    dropdownItemClickHandler(id, event) {
      let index;
      const subHeader = event.target.closest('.option');
      const subContentWrap = event.target.closest('.content-item').querySelector('.sub-content-wrap');
      const heightDiff = subHeader.offsetTop - this.categoryScrolled;
      subContentWrap.style.top = heightDiff + 'px';

      this.filtersData.categories.forEach(item => {
        if (item.id === id) {
          index = item;
          item.isActive = item.isActive !== true;
        } else {
          item.isActive = false;

          if (this.windowWidth <= 767) {
            setTimeout(() => {
              subHeader.scrollIntoView({behavior: "smooth"});
            }, 50);
          }
        }
      });
    },
    activeSubItem(categorySlug) {
      if (this.currentCategorySlug.includes(categorySlug.toLowerCase())) {
        return true;
      }
    },
    activeCluster(title) {
      return title.toLowerCase() === this.currentCluster.toLowerCase();
    },
    tableHeaderItem(itemName) {
      return this.tableColumns.find(item => item.value === itemName);
    },
    tableClassCombined(tableItem) {
      return [tableItem?.align, {'large-padding': tableItem?.largePadding}, tableItem?.isSticky ? 'is-sticky' : '', tableItem?.stickyClass ? tableItem.stickyClass : ''];
    },
    tableStyleCombined(tableItem) {
      return {
        width: tableItem?.width,
        display: !tableItem?.isActive ? 'none' : 'table-cell',
        minWidth: tableItem?.textWidth
      };
    },
    cropImg(imageUrl, size, store) {
      return cropImage(imageUrl, size, store);
    },
    appClickHandler(obj) {
      this.showAppDetailsModal = true;
      // this.openModal('showAppDetailsModal');
      this.clickedItem = {
        ...obj,
        store: obj?.store_key,
      };

    },
    async modalCompetitorChanged(obj) {
      const {type, id, competitorType} = obj;
      await this.competitorChanged(type, id, competitorType);
    },
    initFilterByQueryParams() {
      const parsedQueryParams = parseQueryStringToObject(this.urlQueryString)
      const clusterKeyFromQueryParams = parsedQueryParams.cluster_key
      const categoryFromQueryParams = parsedQueryParams.category_slug
      const iapFromQueryParams = parsedQueryParams.iap

      this.currentCluster = clusterKeyFromQueryParams === 'top-grossing' ? 'Grossing' : 
                            clusterKeyFromQueryParams === 'top-paid' ? 'Paid' : 
                            clusterKeyFromQueryParams === 'top-free' ? 'Free' : '';

      this.currentIap = iapFromQueryParams === 'iap' ? 'IAP' : 
                        iapFromQueryParams === 'no-iap' ? 'No IAP' : 'All'
      
      this.currentCategory = categoryFromQueryParams.charAt(0).toUpperCase() + categoryFromQueryParams.slice(1);;
    },
    initVisibleTableColumns() {
      const columnsFromLocalStore = this.getFromLocalStorage('table-columns');
      this.tableColumns = columnsFromLocalStore ? columnsFromLocalStore : this.tableColumns;
    },
    setDocumentTitle() {
      let title = this.$route.meta.pageTitle;
      if (this.currentApp && this.currentCountry) {
        title = this.storeTitle + ' ' + title + ' ' + this.currentCountry.name;
      }
      this.setPageTitle(title);
    },
  },
  async mounted() {
    this.dataLoaded = false;
    this.currentStore = this.currentApp?.store?.key;

    if (this.currentApp?.store?.key === 'GOOGLE_PLAY') {
      this.currentStoreId = 1;
    } else {
      this.currentStoreId = 2;
    }

    if (getCookie('currentAppId') && this.filtersData.stores?.length === 0) {
      this.currentKeywordCountryName = this.currentCountry?.name;
      this.currentKeywordCountryCode = this.currentCountry?.code;
      this.currentKeywordCountryId = this.currentCountry?.id;

      const initialQueryString = window.location.search;
      if (initialQueryString !== '') {
        let queryString = initialQueryString;
        queryString = queryString.charAt(0).replace('?', '&') + queryString.slice(1);
        this.$store.dispatch('appRanking/CONSTRUCT_URL_QUERY_STRING', queryString);
        this.initialUrlQueryParams = queryString;
      }

      this.setDocumentTitle();

      this.dataLoaded = false;
      await this.sendPromisesBundle();
      await this.setTableData()
      this.setTableColumnsData();
      this.rankingTableScroll();
      this.initVisibleTableColumns();
    }
  },
  unmounted() {
    this.$store.dispatch('appRanking/SET_SORTING_META', {
          order_by: 'rating',
          order_direction: 'desc',
          store_key: this.currentApp?.store?.key,
          cluster_key: 'top-grossing',
          category_slug: 'overall',
          iap: 'all',
          device: this.currentApp?.store?.key === 'APP_STORE' ? 'iphone' : 'mobile',
    });
  },
  computed: {
    ...mapGetters([
      'isMobile',
      'pageMeta',
      'currentApp',
      'promiseSettledData',
      "currentCountry",
      "currentCountryManual",
      'sidebarIsCollapsed',
    ]),
    ...mapGetters('appRanking', [
      'sortingMeta',
      'urlQueryString',
    ]),
    popularityChartResultsLength() {
      return Object.values(this.popularityChartResults)?.length;
    },
    filteredCompData() {
      const categories = this.filtersData.categories.filter(item => item?.store_id === this.currentStoreId || item?.title === 'Overall');
      const clusters = this.filtersData.clusters.filter(item => item?.store_id === this.currentStoreId);
      return {
        clusters,
        categories,
      }
    },
    windowWidth() {
      return window.innerWidth;
    },
    computedTitle() {
      return `${this.currentKeywordCountryName} ${this.currentStore === 'APP_STORE' ? 'App Store' : 'Google Play'} ${this.title}`;
    },
    computedSubtitle() {
      let iapString = '';
      let type = 'Apps';
      let category = this.currentCategory;

      let currDevice = '';

      if (this.currentApp?.store?.key !== 'GOOGLE_PLAY') {
        currDevice = this.currentDevice.toLowerCase() === 'iphone' ? 'iPhone' : 'iPad';
      }

      let cluster = this.currentCluster.toLowerCase() === 'grossing' ? ' ' : (this.currentCluster.toLowerCase() === 'free' ? 'Free ' : 'Paid ');

      if (this.currentIap !== 'All') {
        iapString = this.currentIap === 'IAP' ? 'with iAP' : 'no iAP';
      }

      if (this.currentCategory.toLowerCase().includes('games') || this.currentCategorySlug.includes('game')) {
        type = 'Games';
      }

      if (this.currentCategory.toLowerCase() === 'all games') {
        category = '';
      } else if (this.currentCategory.toLowerCase() === 'overall') {
        category = 'Overall';
      } else {
        category = this.currentCategory;
      }

      return `${category} ${currDevice} Top ${cluster}${type} ${iapString}`;
    },
    storeTitle() {
      return this.currentApp?.store?.key === "APP_STORE" ? "App store" : "Google Play";
    },
  },
  watch: {
    tableColumns(columns) {
      this.saveToLocalStorage('table-columns', columns)
    },
    urlQueryString() {
      if (!this.isSetupFilterFromUrl) {
        this.initFilterByQueryParams()
        this.isSetupFilterFromUrl = true
      }
    },
    async $route(to, from) {
      this.title = to?.meta?.pageTitle ?? '';
    },
    async currentApp(newVal, oldVal) {
      this.currentStore = newVal?.store?.key;
      
      if (this.currentApp?.store?.key === 'GOOGLE_PLAY') {
        this.currentStoreId = 1;
      } else {
        this.currentStoreId = 2;
      }

      if (newVal?.id !== oldVal?.id && oldVal?.id) {
        if (this.filtersData.stores?.length === 0) {
          await this.sendPromisesBundle();
        }

        if (newVal?.store.key !== oldVal.store.key) {
          const defaultFilterData = {
            order_by: 'rating',
            order_direction: 'desc',
            store_key: this.currentApp?.store?.key,
            cluster_key: 'top-grossing',
            category_slug: 'overall',
            iap: 'all',
            device: this.currentApp?.store?.key === 'APP_STORE' ? 'iphone' : 'mobile',
          }

          this.$store.dispatch('appRanking/SET_SORTING_META', defaultFilterData);
          this.$store.dispatch('appRanking/CONSTRUCT_URL_QUERY_STRING', defaultFilterData);
        }

        this.setDocumentTitle();

        this.dataLoaded = false;
        this.currentKeywordCountryCode = 'US';
        this.currentKeywordCountryId = null;
        await this.setTableData();
        this.setTableColumnsData();
        this.initFilterByQueryParams()
        this.initVisibleTableColumns();
      }
    },
    async currentCountryManual(newVal, oldVal) {
      if (newVal?.id !== oldVal?.id) {
        this.currentKeywordCountryCode = newVal.code;
        this.currentKeywordCountryId = newVal.id;
        this.currentKeywordCountryName = newVal?.name;

        const initialQueryString = window.location.search;
        if (initialQueryString !== '') {
          let queryString = initialQueryString;
          queryString = queryString.charAt(0).replace('?', '&') + queryString.slice(1);
          this.$store.dispatch('appRanking/CONSTRUCT_URL_QUERY_STRING', queryString);
          this.initialUrlQueryParams = queryString;
        }

        this.setDocumentTitle();

        await this.updateSort({
          country_code: newVal.code,
        });
      }
    }
  },
}
</script>

<style lang="scss" src="./styles.scss"></style>