export const appRankingTableConfig = {
    headers: [
        {
            text: '',
            mobileText: 'Position',
            sortable: false,
            value: 'position',
            sortBy: 'position',
            align: 'right',
            width: '92px',
            textWidth: '92px',
            isSticky: true,
            stickyClass: 'w92',
            isFrozen: true,
        },
        {
            text: 'Title',
            sortable: false,
            value: 'title',
            sortBy: 'title',
            align: 'left',
            width: '348px',
            textWidth: '348px',
            isSticky: true,
            stickyClass: 'w348',
            isFrozen: true,
        },
        {
            text: 'Publisher',
            value: 'developer',
            sortBy: 'developer',
            width: '190px',
            textWidth: '187px',
            align: 'left',
            sortable: false,
            draggable: false,
            cssClass: 'w220 w160mobile',
        },
        {
            text: 'Rating',
            value: 'rating',
            sortBy: 'rating',
            width: '140px',
            textWidth: '140px',
            align: 'right',
            sortable: false,
            draggable: false,
            cssClass: 'w140 w80mobile',
        },
        {
            text: 'ASO Score',
            value: 'score',
            sortBy: 'score',
            width: '140px',
            textWidth: '140px',
            align: 'right',
            sortable: false,
            draggable: false,
            cssClass: 'w140 w80mobile',
        },
        {
            text: 'Voted',
            value: 'ratings_amount',
            sortBy: 'ratings_amount',
            width: '140px',
            textWidth: '140px',
            align: 'right',
            sortable: false,
            draggable: false,
            cssClass: 'w140 w120mobile',
        },
        {
            text: 'Update Frequency',
            value: 'update_frequency_days',
            sortBy: 'update_frequency_days',
            width: '140px',
            textWidth: '140px',
            align: 'right',
            sortable: false,
            draggable: false,
            cssClass: 'w140 w110mobile',
        },
        // {
        //     text: 'Downloads',
        //     value: 'formatted_downloads_amount',
        //     sortBy: 'downloads_amount',
        //     width: '140px',
        //     textWidth: '140px',
        //     align: 'right',
        //     sortable: false,
        //     draggable: false,
        //     cssClass: 'w140 w110mobile',
        // },
        {
            text: 'App Age',
            value: 'age_days',
            sortBy: 'age_days',
            width: '140px',
            textWidth: '140px',
            align: 'right',
            sortable: false,
            draggable: false,
            cssClass: 'w140 w105mobile',
        },
        {
            text: 'Screenshots',
            value: 'screenshots_amount',
            sortBy: 'screenshots_amount',
            width: '140px',
            textWidth: '140px',
            align: 'right',
            sortable: false,
            draggable: false,
            cssClass: 'w140 w120mobile',
        },
        {
            text: 'Unique Screenshots Locales',
            value: 'unique_screenshots_amount',
            sortBy: 'unique_screenshots_amount',
            width: '180px',
            textWidth: '180px',
            align: 'right',
            sortable: false,
            draggable: false,
            cssClass: 'w180 w180mobile',
        },
        // {
        //     text: 'Has Video',
        //     value: 'has_video_flag',
        //     sortBy: 'has_video_flag',
        //     width: '140px',
        //     textWidth: '140px',
        //     align: 'right',
        //     sortable: false,
        //     draggable: false,
        //     cssClass: 'w140 w105mobile',
        // },
        // {
        //     text: 'Locales',
        //     value: 'locales_amount',
        //     sortBy: 'locales_amount',
        //     width: '140px',
        //     textWidth: '140px',
        //     align: 'right',
        //     sortable: false,
        //     draggable: false,
        //     cssClass: 'w140 w85mobile',
        // },
        {
            text: 'Unique Locales',
            value: 'unique_locales_amount',
            sortBy: 'unique_locales_amount',
            width: '140px',
            textWidth: '140px',
            align: 'right',
            sortable: false,
            draggable: false,
            cssClass: 'w140 w85mobile',
        },
        {
            text: 'Last Update',
            value: 'updated_at',
            sortBy: 'updated_at',
            width: '140px',
            textWidth: '140px',
            align: 'right',
            sortable: false,
            draggable: false,
            cssClass: 'w140 w135mobile',
        },
        {
            text: 'IAP Price From',
            value: 'in_app_purchase_from',
            sortBy: 'in_app_purchase_from',
            width: '140px',
            textWidth: '140px',
            align: 'right',
            sortable: false,
            draggable: false,
            cssClass: 'w140',
        },
        {
            text: 'IAP Price To',
            value: 'in_app_purchase_to',
            sortBy: 'in_app_purchase_to',
            width: '140px',
            textWidth: '140px',
            align: 'right',
            sortable: false,
            draggable: false,
            cssClass: 'w140',
        },
        {
            text: 'Size',
            value: 'size_mb',
            sortBy: 'size_mb',
            width: '140px',
            textWidth: '140px',
            align: 'right',
            sortable: false,
            draggable: false,
            cssClass: 'w161',
        },
        // {
        //     text: 'Website',
        //     value: 'site',
        //     sortBy: 'site',
        //     width: '220px',
        //     textWidth: '220px',
        //     align: 'left',
        //     sortable: false,
        //     draggable: false,
        //     cssClass: 'w220',
        // },
        // {
        //     text: 'Email',
        //     value: 'email',
        //     sortBy: 'email',
        //     width: '220px',
        //     textWidth: '220px',
        //     align: 'left',
        //     sortable: false,
        //     draggable: false,
        //     cssClass: 'w220',
        // },
        // {
        //     text: 'Requires Android',
        //     value: 'latest_rank',
        //     sortBy: 'latest_rank',
        //     width: '140px',
        //     textWidth: '140px',
        //     align: 'right',
        //     sortable: false,
        //     draggable: false,
        // },
        // {
        //     text: 'Capability',
        //     value: 'applications_count',
        //     sortBy: 'applications_count',
        //     width: '140px',
        //     textWidth: '140px',
        //     align: 'right',
        //     sortable: false,
        //     draggable: false,
        // },
    ],
}