<template>
  <div>
    <template v-if="items?.length > 0">
      <div class="advanced-custom-data-table"
           :id="wrapperId">
        <table id="table" class="advanced-custom-table">
          <thead>
          <tr>
            <th v-for="(headerItem, index) in headers"
                :key="index"
                :class="[headerItem.align ? [headerItem.align] : 'left', sortingMeta[sortingKeys.sorting] === headerItem.value ? 'active' : '', headerItem.isSticky ? 'is-sticky' : '', headerItem.stickyClass ? headerItem.stickyClass : '', headerItem.draggable ? '' : 'not-draggable', headerItem.cssClass ?? '']"
                :style="[{ width: headerItem.width ? headerItem.width : '', minWidth: headerItem.textWidth ? headerItem.textWidth : ''}]"
                :hidden="!headerItem.isActive"
                :aria-hidden="!headerItem.isActive">

              <div class="inner-content"
                   :style="{width: `calc: ${headerItem.minWidth} - 54px`}">
                <template v-if="headerItem.draggable">
                  <div class="drag-icon"
                       v-on="headerItem.draggable ? {mousedown: mouseDownHandler}: {}">
                    <div class="drag-dots"></div>
                    <div class="drag-dots"></div>
                  </div>
                </template>

                <template v-if="headerItem.hasCheckbox">
                  <data-table-checkbox :checked="deleteAllCheckboxValue"
                                       @input-checked="checkAllClicked"/>
                  <slot name="multi-delete"></slot>
                </template>

                <div class="inner-content-wrap"
                     @click="sortClickHandler(headerItem.sortBy, headerItem.sortable)">
                  <span>
                     <template v-if="headerItem.sortable">
                    <div class="sort-icons-block">
                      <svg-icon icon="arrow-solid-up"
                                class="icon icon-first"
                                :class="{active: sortingMeta[sortingKeys.sorting] === headerItem.value && sortingMeta[sortingKeys.direction] === 'asc'}"/>
                      <svg-icon icon="arrow-solid-down"
                                class="icon"
                                :class="{active: sortingMeta[sortingKeys.sorting] === headerItem.value && sortingMeta[sortingKeys.direction] === 'desc'}"/>
                    </div>
                  </template>

                    <template v-if="headerItem.tooltipText">
                      <custom-tooltip :width="headerItem.tooltipWidth"
                                      :padding-classes="'padding: 4px 0;'">
                        <template v-slot:slotTrigger>
                          {{ headerItem.text }}
                        </template>
                        <template v-slot:slotContent>
                          <span v-html="headerItem.tooltipText"></span>
                        </template>
                      </custom-tooltip>
                    </template>
                    <template v-else>{{ headerItem.text }}
                      <span class="mobile-visible">{{ headerItem.mobileText }}</span>
                    </template>
                  </span>
                </div>
              </div>

            </th>
          </tr>
          </thead>

          <tbody>
          <tr class="loading-indicator"
              :class="[{active: loading}]">
            <div class="loading">
              <div class="loading_line_wrapper">
                <div class="loading_line">
                  <div class="loading_line_inner loading_line_inner--1"></div>
                  <div class="loading_line_inner loading_line_inner--2"></div>
                </div>
              </div>
            </div>
          </tr>
          <tr class="averages">
            <slot name="averages"></slot>
          </tr>
          <tr v-for="(item, index) in items"
              :key="index"
              class="data-rows"
              :class="{first: index === 0}">

            <slot name="items" :item="item">
              <!-- Fallback content -->
              items list
            </slot>
          </tr>
          </tbody>
        </table>
      </div>
      <div v-if="false" class="custom-pagination">
        <div class="custom-pagination-wrap">
          <button type="button"
                  class="pagination-item"
                  :disabled="pagination.current_page === 1"
                  @click="paginationBtnClickHandler(null, 'prev')">
            <svg-icon icon="angle-left-solid"/>
          </button>

          <button type="button"
                  v-for="link in links"
                  class="pagination-item"
                  :class="{active: link.active}"
                  @click="paginationBtnClickHandler(link.label, null)">{{ link.label }}
          </button>

          <button type="button"
                  class="pagination-item"
                  :disabled="pagination.current_page === pagination.last_page"
                  @click="paginationBtnClickHandler(null, 'next')">
            <svg-icon icon="angle-right-solid"/>
          </button>
        </div>

        <div class="right-part">
          <div class="pages-amount">
            Showing {{ `${pagination.from} - ${pagination.to} of ${pagination.total}` }}
          </div>

          <div class="rows-per-page-block">
            <div class="custom-select-wrap">

              <custom-data-table-select :options="rowsPerPageItems"
                                        direction="up"
                                        :initial-data="'' + pagination.per_page"
                                        @selectClicked="selectClicked"/>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import TableCheckbox from "@/components/UI/TableCheckbox/index";
import CustomDataTableSelect from "@/components/Forms/CustomDataTableSelect/index";

export default {
  name: "CustomDataTable",
  components: {
    'data-table-checkbox': TableCheckbox,
    'custom-data-table-select': CustomDataTableSelect,
  },
  emits: ['check-all-clicked', 'update-sort'],
  props: {
    wrapperId: {
      type: String,
      required: false,
    },
    fixedWidth: {
      type: Boolean,
      default: false
    },
    headers: {
      type: Array
    },
    items: {
      type: Array
    },
    itemsPerPage: {
      type: Number
    },
    rowsPerPageItems: {
      type: Array
    },
    pagination: {
      type: Object
    },
    sortingMeta: {
      type: Object
    },
    sortable: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    checkedTableItemsCount: {
      type: Number,
      required: false,
    },
    hasSelectionArray: {
      type: Array,
      default: () => []
    },
    sortingKeys: {
      type: Object,
      default: () => {
        return {
          direction: 'order_direction',
          sorting: 'order_by'
        }
      }
    }
  },
  data() {
    return {
      deleteAllCheckboxValue: false,
      draggingEle: 0,
      draggingColumnIndex: 0,
      placeholder: 1,
      isDraggingStarted: false,
      x: 0,
      y: 0,
      table: null,
      tableScrollLeft: 0,
      currentDragIconPosition: ''
    }
  },
  mounted() {
    this.table = document.getElementById('table');
    const customDataTable = document.querySelector('.advanced-custom-data-table');
    customDataTable.addEventListener('scroll', () => {
      this.tableScrollLeft = customDataTable.scrollLeft;
    });
  },
  methods: {
    selectClicked(e) {
      this.$emit('update-sort', {
        per_page: e.value,
      });
    },
    paginationBtnClickHandler(value, direction) {
      let page = this.pagination.current_page;

      if (!value) {
        if (direction === 'next') {
          if (this.pagination.current_page < this.pagination.last_page) {
            page++;
          }
        } else {
          if (this.pagination.current_page > 1) {
            page--;
          }
        }
        this.$emit('update-sort', {page});
      } else {
        if (+this.pagination.current_page !== +value) {
          this.$emit('update-sort', {page: value});
        }
      }

      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    },
    sortClickHandler(order_by, sortable) {
      if (!sortable) {
        return;
      }
      let direction;
      if (order_by !== this.sortingMeta[this.sortingKeys.sorting]) {
        direction = 'desc';
      } else {
        direction = this.sortingMeta[this.sortingKeys.direction] === 'desc' ? 'asc' : 'desc';
      }

      const tempKeysObject = {
        [this.sortingKeys.direction]: direction,
        [this.sortingKeys.sorting]: order_by,
      }
      this.$emit('update-sort', tempKeysObject);
    },
    checkAllClicked() {
      this.deleteAllCheckboxValue = !this.deleteAllCheckboxValue;
      this.$emit('check-all-clicked', this.deleteAllCheckboxValue);
    },
    swap(nodeA, nodeB) {
      const parentA = nodeA.parentNode;
      const siblingA = nodeA.nextSibling === nodeB ? nodeA : nodeA.nextSibling;

      // Move `nodeA` to before the `nodeB`
      nodeB.parentNode.insertBefore(nodeA, nodeB);

      // Move `nodeB` to before the sibling of `nodeA`
      parentA.insertBefore(nodeB, siblingA);
    },
    isOnLeft(nodeA, nodeB) {
      // Get the bounding rectangle of nodes
      const rectA = nodeA.getBoundingClientRect();
      const rectB = nodeB.getBoundingClientRect();

      return rectA.left + rectA.width / 2 < rectB.left + rectB.width / 2;
    },
    cloneTable() {
      const rect = this.table.getBoundingClientRect();

      this.list = document.createElement('div');
      this.list.classList.add('clone-list');
      this.list.classList.add('custom-table');
      this.list.style.position = 'absolute';
      // this.list.style.left = `${rect.left}px`;
      this.list.style.left = `0px`;
      // this.list.style.top = `${rect.top}px`;
      this.list.style.top = `0px`;
      this.table.parentNode.insertBefore(this.list, this.table);

      // Hide the original table
      this.table.style.visibility = 'hidden';

      // Get all cells
      const originalCells = [].slice.call(this.table.querySelectorAll('tbody td:not([hidden])'));

      const originalHeaderCells = [].slice.call(this.table.querySelectorAll('th:not([hidden])'));
      const numColumns = originalHeaderCells.length;

      // Loop through the header cells
      originalHeaderCells.forEach((headerCell, headerIndex) => {
        const width = parseInt(window.getComputedStyle(headerCell).width);

        // if (!headerCell.getAttribute('hidden')) {
        // Create a new table from given row
        const item = document.createElement('div');
        item.classList.add('draggable');

        const newTable = document.createElement('table');
        newTable.setAttribute('class', 'clone-table');
        newTable.style.width = `${width}px`;

        // Header
        const th = headerCell.cloneNode(true);
        let newRow = document.createElement('tr');
        newRow.appendChild(th);
        newTable.appendChild(newRow);

        const cells = originalCells.filter(function (c, idx) {
          return (idx - headerIndex) % numColumns === 0;
        });
        cells.forEach(function (cell) {
          const newCell = cell.cloneNode(true);
          newCell.style.width = `${width}px`;
          newRow = document.createElement('tr');
          newRow.appendChild(newCell);
          newTable.appendChild(newRow);
        });

        item.appendChild(newTable);
        this.list?.appendChild(item);
        // }
      });
    },
    mouseDownHandler(e) {
      e.stopPropagation();
      this.currentDragIconPosition = e.target?.closest('th:not([hidden])')?.classList?.contains('left') ? 'left' : 'right';
      this.draggingColumnIndex = [].slice.call(this.table.querySelectorAll('th:not([hidden])')).indexOf(e.target?.closest('th'));

      // Determine the mouse position
      this.x = e.clientX - e.target.offsetLeft;
      this.y = e.clientY - e.target.offsetTop;

      // Attach the listeners to `document`
      document.addEventListener('mousemove', this.mouseMoveHandler.bind(this));
      document.addEventListener('mouseup', this.mouseUpHandler.bind(this));
    },
    mouseMoveHandler(e) {
      if (!this.isDraggingStarted && this.draggingColumnIndex >= 0) {
        this.isDraggingStarted = true;

        this.cloneTable();
        this.draggingEle = [].slice.call(this.list.children)[this.draggingColumnIndex];
        this.draggingEle.classList.add('dragging');

        // Let the placeholder take the height of dragging element
        // So the next element won't move to the left or right
        // to fill the dragging element space
        this.placeholder = document.createElement('div');
        this.placeholder.classList.add('placeholder');

        this.draggingEle?.parentNode?.insertBefore(this.placeholder, this.draggingEle.nextSibling);
        this.placeholder.style.width = `${this.draggingEle.offsetWidth}px`;
      }

      // Set position for dragging element

      if (this.draggingEle) {
        const sidebarWidth = document.querySelector('aside.sidebar')?.clientWidth;
        this.draggingEle.style.position = 'absolute';
        this.draggingEle.style.top = `${this.draggingEle.offsetTop}px`;
        if (this.currentDragIconPosition === 'left') {
          this.draggingEle.style.left = `${e.clientX + this.tableScrollLeft - this.draggingEle.clientWidth - sidebarWidth - 32 + 8}px`;
        } else {
          this.draggingEle.style.left = `${e.clientX + this.tableScrollLeft - sidebarWidth - 32 - 8}px`;
        }

        // Reassign the position of mouse
        this.x = e.clientX;
        this.y = e.clientY;

        // The current order
        // prevEle
        // draggingEle
        // placeholder
        // nextEle
        const prevEle = this.draggingEle.previousElementSibling;
        const nextEle = this.placeholder.nextElementSibling;

        // // The dragging element is above the previous element
        // // User moves the dragging element to the left

        if (!prevEle?.querySelector('th')?.classList?.contains('not-draggable') && !nextEle?.querySelector('th')?.classList?.contains('not-draggable')) {
          if (prevEle && this.isOnLeft(this.draggingEle, prevEle)) {
            // The current order    -> The new order
            // prevEle              -> placeholder
            // draggingEle          -> draggingEle
            // placeholder          -> prevEle
            this.swap(this.placeholder, this.draggingEle);
            this.swap(this.placeholder, prevEle);
            return;
          }

          // The dragging element is below the next element
          // User moves the dragging element to the bottom
          if (nextEle && this.isOnLeft(nextEle, this.draggingEle)) {
            // The current order    -> The new order
            // draggingEle          -> nextEle
            // placeholder          -> placeholder
            // nextEle              -> draggingEle
            this.swap(nextEle, this.placeholder);
            this.swap(nextEle, this.draggingEle);
          }
        } else {
          if (prevEle && this.isOnLeft(this.draggingEle, prevEle)) {
            return;
          }
          if (nextEle && this.isOnLeft(nextEle, this.draggingEle)) {
            this.swap(nextEle, this.placeholder);
            this.swap(nextEle, this.draggingEle);
          }
        }
      }
    },
    mouseUpHandler() {
      // Remove the placeholder
      if (this.placeholder) {
        this.placeholder?.remove();
      }

      this.draggingEle?.classList?.remove('dragging');
      this.draggingEle?.style?.removeProperty('top');
      this.draggingEle?.style?.removeProperty('left');
      this.draggingEle?.style?.removeProperty('position');

      // Get the end index
      if (this.draggingEle) {
        const endColumnIndex = [].slice.call(this.list?.children).indexOf(this.draggingEle);

        this.isDraggingStarted = false;

        // Remove the `list` element
        // this.list.parentNode.removeChild(this.list);
        this.list.remove();

        // Move the dragged column to `endColumnIndex`
        this.table.querySelectorAll('tr').forEach(row => {
          const cells = [].slice.call(row.querySelectorAll('th:not([hidden]), td:not([hidden])'));
          this.draggingColumnIndex > endColumnIndex
              ? cells[endColumnIndex]?.parentNode?.insertBefore(
                  cells[this.draggingColumnIndex],
                  cells[endColumnIndex]
              )
              : cells[endColumnIndex]?.parentNode?.insertBefore(
                  cells[this.draggingColumnIndex],
                  cells[endColumnIndex]?.nextSibling
              );
        });

        document.removeEventListener('mousemove', this.mouseMoveHandler);
        document.removeEventListener('mouseup', this.mouseUpHandler);

        this.draggingEle = false;
        this.draggingColumnIndex = -1;
        this.table.style.removeProperty('visibility');
      }
    }
  },
  computed: {
    links() {
      return this.pagination.links.filter(link => {
        const label = link.label.includes('Previous') || link.label.includes('Next');
        if (!label) {
          return {
            value: +link.label,
            active: link.active
          }
        }
      });
    }
  },
  watch: {
    checkedTableItemsCount(newVal, oldVal) {
      if (newVal < oldVal) {
        this.deleteAllCheckboxValue = false;
      }
    }
  }
}
</script>

<style src="./styles.scss" lang="scss"></style>